import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import MapDetails from "../pages/MapDetails";
import { getSupplierById } from "../store/actions/userActions";
import loadingGif from '../assets/imgs/loading-gif.gif'


export function MapShare() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [supp, setSupp] = useState(null);
  const [spinner, setSpinner] = useState(true);

  const getDirection = async (e, supp) => {
    e.preventDefault();
    // const googleLink = "https://www.google.com/maps/dir/?api=1&destination=";
    // const position = `${supp.latitude},${supp.longtitude}`;
    // const destinationToURL = encodeURIComponent(position);
    // const destinantionLink = googleLink + destinationToURL;
    // window.open(destinantionLink, "_blank");
    const googleLink = `https://www.google.com/maps/place/${supp.latitude},${supp.longtitude}`;
    window.open(googleLink, "_blank");
  };

  useEffect(() => {
    dispatch(getSupplierById(location?.search.split("?supId=")[1])).then((res) => {
      setSpinner(false);
      setSupp(res.data[0]);
    });
  }, [dispatch, location]);

  if (spinner) {
    return (
      <section className="network">
        <span className="gif-container">
          <img className="loading-gif" src={loadingGif} alt="ok" />
        </span>
      </section>
    );
  }

  if (supp === undefined) {
    return (
      <div className={"map-detail"}>
        <div className="map-bg"></div>
        <div className="map-container">
          <div className="map-div-120 map-share">
            <div className="div-10">
              <div className="box">
                <span className="msg">プロバイダーが存在しません</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"map-detail"}>
      <div className="map-bg"></div>
      <div className="map-container">
        <div className="map-div-120 map-share">
          <div className="div-10">
            <div className="div-11">{supp && supp.name}</div>
            <div className="div-12">
              <div className="div-13">所在地</div>
              <div className="div-14">
                〒
                {supp && supp.address_postalcode}
                <br />
                {supp && supp.address_detail}
              </div>
            </div>
            <div className="div-15" />
            <div className="div-12">
              <div className="div-13">電話</div>
              <div className="div-14">
                {/* {supp && supp.company_phone_number} */}
                {supp && (
                  supp.company_phone_number
                    ? `${supp.company_phone_number.substring(0, 3)}-${supp.company_phone_number.substring(3, 6)}-${supp.company_phone_number.substring(6)}`
                    : ''
                )}
              </div>
            </div>
            <div className="div-15" />
            <div className="div-12">
              <div className="div-13">営業時間</div>
              <div className="div-14">
                {supp.business_start_hour ? supp.business_start_hour.substring(0, 5) : ""} {supp.business_start_hour && "-"} {supp.business_end_hour ? supp.business_end_hour.substring(0, 5) : ""}
                {supp.all_day_business_possible === 1 ? " (24時間営業)" : " (営業時間内対応)"}
                <br />
                {/* （休憩時間 {supp.rest_start_hour && "/"} {supp.rest_start_hour ? supp.rest_start_hour.substring(0, 5) : ""} {supp.rest_start_hour && "〜"} {supp.rest_end_hour ? supp.rest_end_hour.substring(0, 5) : ""} */}
                {(supp.rest_start_hour || supp.rest_end_hour || supp.rest_start_hour_2 || supp.rest_end_hour_2) && (
                  <>
                    （休憩時間 
                    {supp.rest_start_hour && ": "} 
                    {supp.rest_start_hour ? supp.rest_start_hour.substring(0, 5) : ""} 
                    {supp.rest_start_hour && "〜"} 
                    {supp.rest_end_hour ? supp.rest_end_hour.substring(0, 5) : ""} 
                    {supp.rest_start_hour_2 && " / "} 
                    {supp.rest_start_hour_2 ? supp.rest_start_hour_2.substring(0, 5) : ""} 
                    {supp.rest_start_hour_2 && "〜"} 
                    {supp.rest_end_hour_2 ? supp.rest_end_hour_2.substring(0, 5) : ""}）
                  </>
                )}
              </div>
            </div>
            <div className="div-15" />
            <div className="div-12">
              <div className="div-13">定休日</div>
              <div className="div-14">
                {
                  [
                    supp.is_regular_day_off_on_monday && "月曜日",
                    supp.is_regular_day_off_on_tuesday && "火曜日",
                    supp.is_regular_day_off_on_wednesday && "水曜日",
                    supp.is_regular_day_off_on_thursday && "木曜日",
                    supp.is_regular_day_off_on_friday && "金曜日",
                    supp.is_regular_day_off_on_saturday && "土曜日",
                    supp.is_regular_day_off_on_sunday && "日曜日",
                    supp.is_regular_day_off_on_holiday && "祝日",
                  ]
                  .filter(Boolean)
                  .join("、") || "定休日記載なし"
                }
                <br />
                {supp && supp.regular_day_off_note}
              </div>
            </div>
            <div className="div-15" />
            <div className="div-12">
              <div className="div-13">休憩時間</div>
              <div className="div-14">
                {supp.rest_start_hour ? supp.rest_start_hour.substring(0, 5) : ""} {supp.rest_start_hour ? '~' : ''} {supp.rest_end_hour ? supp.rest_end_hour.substring(0, 5) : ""}
                <br />
                {supp.rest_start_hour_2 ? supp.rest_start_hour_2.substring(0, 5) : ""} {supp.rest_start_hour_2 ? '~' : ''} {supp.rest_end_hour_2 ? supp.rest_end_hour_2.substring(0, 5) : ""}
              </div>
            </div>
            <div className="div-15" />

            <div className="div-12">
              <div className="div-13">取扱品目</div>
              <div className="div-141">
                {/* {supp.category} */}
                {
                  supp.category && supp.category.split(", ").map(function (item, idx) {
                    return (
                      <p key={idx}>
                        {item}
                        <br />
                      </p>
                    )
                  })
                }
              </div>
            </div>

            <div className="div-15" />

            <div className="div-12">
              <div className="div-14">
                {/* {supp.all_day_business_possible === 1 ? "24時間営業 " : "営業時間内対応 "} */}
                {/* {supp.rest_start_hour ? supp.rest_start_hour.substring(0, 5) : ""} {supp.rest_start_hour && "-"} {supp.rest_end_hour ? supp.rest_end_hour.substring(0, 5) : ""} 休憩時間 */}
                {/* <br></br> */}
                {supp.detail}
                <br></br>
              </div>
            </div>
            <div className="div-16">
              <div className="div-17"></div>
              <div className="div-191">掲載されている情報に誤りがある際はお知らせください</div>
            </div>
          </div>
          {supp && <MapDetails supp={supp} cln={"map-div-122"}/>}
          <div onClick={(e) => getDirection(e, supp)} className="float-btn2">
            GoogleMapで見る
          </div>
        </div>
      </div>
    </div>
  );
}
